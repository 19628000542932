.input-password {
  position: relative;
}

.show-password {
  cursor: pointer;
  font-size: 38px;
  height: 100%;
  line-height: 100%;
  position: absolute;
  right: 110px;
  top: 0;

  &:hover {
    color: $rouge;
  }
}

.btn-copy-password {
  transition: 0.5s all;
  width: 100px;
}

.btn-copy-password.ok {
  background-color: $btn-orange;
}
