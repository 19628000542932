@import 'modules/nav';
@import 'modules/buttons';
@import 'modules/entete';
@import 'modules/sweetalert';
@import 'modules/table';
@import 'modules/password';
@import 'modules/workinprogress';

/*************** Menu User ****************/
.m-user-menu {
  .link {
    padding: 10px 0;
  }
}

/*************** Devis Instant ******************/

.m-recap .block-link-action {
  text-align: right;
}

.m-recap .link-action {
  display: inline-block;
  padding-left: 10px;
}

.m-recap .price-column {
  text-align: right;
}

.m-empty-piece {
  background-color: $gris-clair;
  border: 1px dotted $gris-fonce;
  margin: 50px auto;
  padding: 70px;
  text-align: center;
  width: 70%;
}
