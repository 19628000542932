@import 'vars';

/************* Général ******************/
body {
  color: $gris;
  font-family: Verdana, Arial, sans-serif;
  font-size: 14px;
}

h1,
h2,
h3,
h4 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
}

h1 {
  color: $rouge;
}

h2 {
  color: $gris;
}

a {
  color: $noir;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    color: $rouge;
  }
}

.breadcrumbs a:hover {
  color: $rouge !important;
}

ul {
  list-style-type: none;
}

.gras {
  font-weight: bold;
}

.rouge {
  color: $rouge;
}

.titre {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
}

.ta-r {
  text-align: right;
}

form {
  width: 100%;
}

label {
  cursor: pointer;
}

form img {
  width: 100%;
}

.input-group {
  width: 100%;
}

.full-width {
  margin-left: auto;
  margin-right: auto;
  max-width: inherit;
  width: 100%;
}

.no-padding {
  .columns {
    padding: 0;
  }
}

.back-to-grid {
  background: inherit;
  margin: 0 auto !important;
  max-width: 62.5rem !important;

  .columns {
    padding: 0 0.9375rem;
  }
}

.small {
  font-size: 0.6667rem;
}

i.large {
  font-size: rem-calc(50);
}

i.medium {
  font-size: rem-calc(35);
}

/************** Breadcrumbs *********************/
.breadcrumbs {
  margin-bottom: 0;

  a {
    color: $rouge;
  }

  a:hover {
    color: $blanc;
  }

  li:not(:last-child)::after {
    color: $rouge;
  }
}

/*********** Form Errors *******************/
.form-error {
  color: $error;
  display: block;
  margin-bottom: 0;
  margin-top: 0;
}

.error-message {
  color: $error;
}

i {
  vertical-align: middle;

  &.medium {
    font-size: 30px;
  }
}

.py-1 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
