/*************** Tables ******************/
.m-table {
  thead {
    border-bottom: 2px solid $orange;
  }

  tfoot {
    border-top: 2px solid $orange;
  }

  .center {
    text-align: center;
  }
}
