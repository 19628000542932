$bleu-clair: #a4faff;
$rouge: #87130f;
$gris-clair: #ececec;
$gris: #666;
$noir: #2b2b2b;
$blanc: #fff;
$ombre: #666;
$gris-fonce: #333;
$error: #f00;
$orange: #ff9834;

$btn-orange: #ff9834;
$btn-mauve: #ccccfe;

$bg-gris: #2b2b2b;
