/************* Boutons ******************/
.m-btn {
  border-radius: 10px;
  color: $rouge;
  font-size: rem-calc(20);
  font-weight: bold;
  padding: 16px;

  &.small {
    font-size: rem-calc(12);
    padding: 8px;
  }

  &.orange {
    background-color: $btn-orange;
  }

  &.gris {
    background-color: $gris-clair;

    &:hover {
      color: $rouge;
    }
  }

  &.mauve {
    background-color: $btn-mauve;
  }

  &.rouge {
    background-color: $rouge;
    color: $blanc;
  }

  &.square {
    border-radius: 0;
  }
}
