// @import 'compass';

/********************** Layout ******************************/
header {
  position: relative;
  z-index: 10;
}

footer {
  color: $noir;
  margin-bottom: 30px;
  margin-top: 12px;
  text-align: center;

  .copyright {
    font-weight: bold;
    margin-top: 10px;
  }
}

.l-artisan {
  a,
  a:hover {
    color: $rouge;
  }
}

/****************** Accueil *****************************/
.l-home {
  background: url('../img/bg-accueil_623x410.png') right center no-repeat;
  position: relative;

  .titre {
    color: $rouge;
    font-size: rem-calc(45);
    font-weight: bold;
    line-height: rem-calc(50);
    padding-left: 35px;
    padding-top: 35px;
    position: relative;
    text-align: right;
  }

  .info {
    font-size: rem-calc(27);
    margin-top: 60px;
    text-align: center;
  }

  .btn-devis {
    background: url('../img/fleche-accueil_744x87.png') left top no-repeat;
    height: 117px;
    margin-top: 55px;
    padding-bottom: 30px;

    a {
      color: $rouge;
      font-size: rem-calc(30);
      line-height: rem-calc(72);
      margin-left: 55px;

      &:hover {
        color: $noir;
      }
    }
  }

  .garantie-rc-dec {
    bottom: 15px;
    position: absolute;
    right: 50px;
    text-align: center;

    a {
      color: $rouge;
      font-size: rem-calc(16);

      &:hover {
        color: $noir;
      }
    }
  }
}

/************* Page classique *********************/
.l-content {
  margin-top: 25px;
  text-align: justify;

  form {
    text-align: left;
  }

  .images {
    img {
      border: 1px solid $gris;
      display: block;
      margin-bottom: 10px;
      padding: 2px;
      width: 100%;
    }

    .bloc-image {
      padding: 2px;

      img {
        border: unset;
      }

      .txt {
        text-align: center;
      }
    }

    .texte {
      display: block;
      font-size: rem-calc(11);
      margin-bottom: 10px;
    }
  }
}

/************* Flèches de tris sur les headers **************/

table {
  thead {
    a.asc {
      &::before {
        color: #000;
        content: '\2227\00A0';
      }
    }

    a.desc {
      &::before {
        color: #000;
        content: '\2228\00A0';
      }
    }
  }
}

.l-title {
  background-color: $bleu-clair;
  color: $noir;
  font-weight: bold;
  margin-top: 5px;
  padding: 5px;
  width: 100%;

  &.bottom {
    background-color: $bg-gris;
  }

  .left {
    font-size: rem-calc(35);
    line-height: rem-calc(35);
    text-align: left;
  }

  .right {
    font-size: rem-calc(20);
    line-height: rem-calc(35);
    text-align: right;
    vertical-align: middle;
  }

  .center {
    color: $blanc;
    font-size: rem-calc(18);
    font-weight: normal;
    text-align: center;

    .titre {
      color: $bleu-clair;
      font-size: rem-calc(24);
      font-weight: bold;
    }

    a {
      color: $blanc;
      font-weight: bold;

      &:hover {
        color: $bleu-clair;
      }
    }
  }
}
