
/***************** Style Menus ******************/
.top-bar-link {
  @include vertical-center;
  position: relative;
}

.bg-top-bar {
  background-color: $noir;
  width: 100%;

  .titre {
    color: $bleu-clair;
    display: inline-block;
    font-size: rem-calc(25);
    line-height: rem-calc(25);
    vertical-align: top;

    &:hover {
      color: $blanc;
    }
  }

  .top-bar-title {
    float: left;
    margin-right: 0;
  }
}

.dropdown.menu a,
.dropdown.menu>li>a {
  background-color: transparent;
}

.dropdown.menu .is-active > a {
  color: $bleu-clair;
}

.dropdown.menu .bloc-compte.is-active > a {
  color: $blanc;
}

.top-bar {
  padding: 0;

  .top-bar-left {
    li {
      height: 62px;
      max-height: 100px;
    }
  }

  ul.menu.dropdown {
    height: 62px;
  }

  a {
    color: $blanc;
    font-size: rem-calc(14);
    font-weight: bold;

    &:hover {
      color: $bleu-clair;
    }
  }

  .bloc-compte {
    background-color: $bleu-clair;
    height: 62px;

    @include breakpoint(medium down) {
      background-color: $noir;

      .link-compte {
        color: $blanc;

        &:hover {
          color: $bleu-clair;
        }
      }
    }

    .link-compte {
      color: $noir;

      &:hover {
        color: $noir;
      }
    }

    [type='submit'] {
      width: 100%;
    }
  }
}
