.m-entete {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: $ombre 0 2px 10px;
  z-index: 0;

  .entete {
    padding: 15px;
  }

  .image {
    text-align: center;

    img {
      display: inline-block;
      max-height: 330px;
      max-width: 100%;
    }
  }

  .infos {
    .titre {
      font-size: rem-calc(40);
      font-weight: bold;
      margin-top: 40px;
    }

    .texte {
      line-height: rem-calc(26);
      margin-top: 30px;
    }
  }

  .m-breadcrumbs {
    background-color: $bleu-clair;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 0;
    padding: 15px;
  }
}



/************* Entete Info ****************/
.m-entete-info {
  .infos {
    .titre {
      margin-top: 0;
    }
  }
}

/************* Témoignages ***************/
.m-entete-temoignage {
  background: url('../img/temoignages_899x332.jpg') left top no-repeat;
  background-size: cover;
  color: $blanc;
  height: 360px;

  .titre {
    font-size: rem-calc(40);
  }
}

.m-temoignage {
  margin-top: 20px;

  .auteur {
    font-size: rem-calc(10);
    line-height: rem-calc(21);
  }
}

/*********** Guides Administratifs *************/
.m-entete-guides {
  .image {
    text-align: left;
  }
}
