@import 'vars';
@import 'foundation-config';
@import '~motion-ui/src/motion-ui';
@import 'general';
@import 'layout';
@import 'module';
@import 'spacing';

@include motion-ui-transitions;
@include motion-ui-animations;

@include foundation-prototype-classes;

label.mandatory:after {
    content: ' *';
    color: red;
    display: inline;
}